#body {
    font-family: Helvetica, sans-serif;
    color: #000000;
    margin: 0px;
    font-size: 13px;
}

.pos_relative{
    position: relative;
}

/* header */
.nav_bar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    height: 65px;
    background-color: rgba(255, 255, 255, 0.9);
    z-index: 10;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.placeholder {
    height: 65px;
}

.icon {
    color: #000000;
    text-decoration: none;
    font-size: 20px;
    text-align: center;
    padding: 20px;
}

.logo {
    padding: 5px 50px;
    width: 70px;
}

.mobile_nav {
    height: 0;
    width: 100%;
    position: fixed;
    z-index: 10;
    top: 0;
    left: 0;
    background-color: rgba(255, 255, 255, 0.95);
    overflow-y: hidden;
    transition: 0.5s;
}

.mobile_nav_list {
    position: relative;
    top: 25%;
    width: 100%;
    text-align: center;
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
}

.mobile_nav_list a {
    font-weight: 100;
    text-decoration: none;   
    color: #000000;
    font-size: 18px;
    letter-spacing: 1px;
    width: fit-content;
    text-align: center;
    margin: 10px;
    border-bottom: 2px solid transparent;
    transition: transform .3s;
}

.mobile_nav_list a:hover {
    border-bottom: 2px solid #F9AE00;
    width: fit-content;
    transform: scale(1.05);
}

.closebtn {
    position: absolute;
    top: 10px;
    right: 20px;
    font-size: 30px;
    text-align: center;
    font-weight: 300;
    text-decoration: none;   
    color: #000000;
}
  

/* homepage content (articles) */
/********* slideshow ***********/
/* Caption text */
.cover_text {
    color: #ffffff;
    font-size: 13px;
    font-weight: 100;
    position: absolute;
    font-family: Arial, Helvetica, sans-serif;
    top: 43%;
    width: 100%;
    text-align: center;
    text-shadow: black;
    letter-spacing: 5px;
    left: 50%;
    transform: translateX(-50%);
    max-width: 80%;
}

.cover_sub_text {
    color: #ffffff;
    font-size: 27px;
    font-weight: 100;
    font-family: Arial, Helvetica, sans-serif;
    position: absolute;
    top: 47%;
    width: 100%;
    text-align: center;
    text-shadow: black;
    letter-spacing: 6px;
    left: 50%;
    transform: translateX(-50%);
    max-width: 80%;
}

.para {
    top: 48%;
    font-size: 12px;
    font-weight: 300;
    letter-spacing: unset;
    text-shadow: 1px 1px black;
}

.cover_btn {
    position: absolute;
    top: 57%;
    left: 50%;
    margin: 0px 0px 0px -60px;
    
}

.slideshow-container {
    width: 100%;
    height: 854px;
    position: relative;
    text-align: center; /* ensures the image is always in the h-middle */
}

.mySlides {
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: none;

    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.mobile-container {
    display: block;
    overflow: hidden;
    width: 100%;
    height: 700px;
    position: relative;
    text-align: center;
}

.fade {
    -webkit-animation-name: fade;
    -webkit-animation-duration: 1.5s;
    animation-name: fade;
    animation-duration: 1.5s;
}

@-webkit-keyframes fade {
    from {opacity: .4}
    to {opacity: 1}
}

@keyframes fade {
    from {opacity: .4}
    to {opacity: 1}
}

/******* slideshow ***********/
.tech img, .minc img {
    width: 100%;
}

.shop h1, .menu h1, .news h1, .about_intro h1, .about_intro2 h1 {
    font-size: 23px;
    font-weight: 700;
    border-bottom: 2.5px solid #F9AE00;
    width: fit-content;
}

.shop h1, .menu h1 {
    text-align: center;
}

.shop, .menu {
    background-color: #faaf00;   
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: transparent;
    margin-top: 50px;
}

.Wait_introduction {
    margin: 80px;
    color: #000000;
    text-align: center;
}

.news_text h2 {
    font-size: 15px;
    font-weight: 300;
    text-align: left;
    max-width: 750px;
    margin: 20px;
}

/******** edtied *********/
.news {
    padding: 50px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-image: url(../image/news_bg.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.news_text {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    padding: 20px;
    width: 150px;
    height: 270px;
    margin: 20px;
    box-shadow: 0px 0px 20px rgb(187, 187, 187);
    background-color: white;
}
/******** edtied *********/

.news_text span {
    font-size: 14px;
    font-weight: 100;
    background-color: white;
    color: #F9AE00;
    border: 1px solid #F9AE00;
    padding: 15px;
    margin: 20px;
}

.more_btn {
    display: block;
    padding: 10px;
    background-color: #F9AE00;
    width: 100px;
    color: #ffffff;
    text-decoration: none;
    letter-spacing: 1px;
    text-align: center;
    transition: transform .5s;
}

.more_btn:hover {
    transform: scale(1.10);
}


footer {
    background-color: black;
    color: white;
    text-align: center;
    font-size: 6px;
    font-weight: lighter;
    padding: 30px;
}
footer a, footer .fb, footer .ig{
    display: inline-block;
    vertical-align: middle;
}
footer img {
    display: inline-block;
    width: 30px;
    height: 30px;
}

.shop_tn, .menu_tn {
    position: relative;
}

.shop_tn:hover img, .menu_tn:hover img {
    opacity: 0.1;
}

.shop_tn:hover .middle, .menu_tn:hover .middle {
    opacity: 1;
}

.middle {
    transition: .5s ease;
    opacity: 0;
    position: absolute;
    z-index: 100;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
  }
  
.see_more {
    color:#F9AE00;
    font-size: 20px;
    font-weight: 700;
    letter-spacing: 2px;
    padding: 16px 32px;
    text-decoration: none;
}

.menu img, .shop img {
    width: 99%;
    transition: .5s ease;
    backface-visibility: hidden;
}


.home_news {
    background-color: #F9AE00;
}

.news_text_container {
    display: flex;
    overflow: auto;
    width: 100%;
}

.hor_scroll {
    display: flex;
}

/* menu */
.douhua1 {   
    text-align: center;
}

.douhua2 {
    text-align: center;
    background-color: #FBF9EB;   
}

.douhua1 img, .douhua2 img {
    width: 75%;

    padding: 10px;
    padding-top: 30px;
}



.menu_intro {
    padding: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.menu_row {
    padding: 30px;
}

.menu_intro h1 {
    font-size: 15px;
    display: block;
    padding: 7px;
    margin: 2px;
    background-color: #F9AE00;
    color: #ffffff;
    text-decoration: none;
    letter-spacing: 1px;
    text-align: center;
    width: fit-content;
}

.menu_category {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    background-color: #6C88A1;
    padding: 10px;
    /* avoid overlapping with nav bar */
    margin-top: 65px;
}

.menu_category a {
    margin: 5px;
    font-weight: 300;
    text-decoration: none;   
    display: inline-block;
    color: #FFFFFF;
}

ul {
    padding: 0px;
}

ul li {
    display: inline;
    text-align: center;
}

hr {
    height: .15rem;
    width: 0%;
    margin: 0;
    background: #F9AE00;
    border: none;
    transition: .3s ease-in-out;
}

.douhua2 {
    animation: expandRight .7s ease forwards;
}

.douhua1 {
    animation: expandLeft .7s ease forwards;
}

/* about us */
.about_doday {
    background-color: transparent;
    width: 100%;
}

.about_doday_img, .about_doday2_img {
    display: none;
}

.about_doday_img_mobile, .about_doday2_img_mobile {
    display: block;
    width: 100%;
}

.about_intro_container {
    background-color: #ffffff;
}

.about_intro2_container {
    background-color: #f6f6f6;
}

.about_doday2 {
    background-color: #6C88A1;
}

.girl {
    display: none;
}

.about_intro, .about_intro2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 50px;
}

@keyframes expandRight {
    0% {
      transform: translateX(1000px);
    }
    100% {
      transform: translateX(0px);
    }
}

@keyframes expandLeft {
    0% {
      transform: translateX(-1000px);
    }
    100% {
      transform: translateX(0px);
    }
}
@media (min-width: 400px) {
    .douhua1 img, .douhua2 img {
        width: 75%;
        height: auto;
        min-width: 280px;
        margin: 40px;
    }
    .cover_text {
        font-size: 15px;
    }

    .cover_sub_text {
        font-size: 30px;
    }

    footer {
        font-size: 7px;
    }
}
@media (min-width: 600px) {
    body {
        
        text-align: left;
        font-weight: lighter;
    }
    .news_text {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-evenly;
        padding: 20px;
        width: unset;
        height: unset;
        box-shadow: unset;
        background-color: unset;
        margin: unset;
    }

    .cover_text {
        font-size: 23px;
        font-weight: 100;
    }

    .cover_sub_text {
        font-size: 43px;
        font-weight: 100;
    }
    

    .news_text_container {
        display: block;
    }

    .hor_scroll {
        display: unset;
    }


    
    .para {
        font-size: 15px;
    }

    .nav_bar {
        height: 75px;
    }

    .icon {
        font-size: 30px;
    }
    
    .logo {
        width: 90px;
    }
}

@media (min-width: 1250px) {
    body {
        
        
        text-align: left;
        font-weight: lighter;
    }

    .menu h1, .shop h1, .about_intro h1, .about_intro2 h1, .news h1{
        padding-top: 2px;
        padding-bottom: 2px;
        width: fit-content;
        border-bottom: 2.5px solid #F9AE00;
        font-size: 30px;
        font-weight: 700;
        line-height: 160%;
        letter-spacing: 2px;
    }

    .news h1{
        margin: 50px;
    }

    .news_text {
        width: unset;
        height: unset;
        margin: unset;
        box-shadow: unset;
        justify-content: space-between;
    }

    .news_text h2 {
        font-size: 20px;
        font-weight: 300;
        text-align: left;
        max-width: 750px;
        margin: 20px;
    }

    .shop_menu {
        margin-top: 100px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .shop, .menu {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .news {
        align-items: flex-start;
        background-color: transparent;
        background-image: unset;
        display: flex;
        flex-direction: row;
        width: auto;
        z-index: 4;
    }

    .news_text_container {
        display: flex;
        flex-direction: column;
        border-left: 1px solid #000000;
        width: 70%;
    }

    .news_text span {
        font-size: 16px;
        font-weight: 100;
        background-color: white;
        color: #F9AE00;
        border: 1px solid #F9AE00;
        padding: 15px;
        margin: 20px;
        margin-left: 30px;
        margin-right: 30px;
    }

    /** **/
    .home_news {
        top: 1750px;
        height: 360px;
        width: 80%;
        margin-top: 200px;
    }

    .news_container {
        height: fit-content;
        width: 80%;
        position: absolute;
        bottom: 140px;
        right: 0px;
        z-index: 2;
        box-shadow: 0px 0px 20px rgb(187, 187, 187);
        background-color: #ffffff;
    }
 
    footer {
        font-size: 8px;
    }

    footer img {
        width: 30px;
        height: 30px;
    }

    /* menu page */
    /****** edited *******/
    .menu_category {
        padding: 50px;
        /* avoid overlapping with nav bar */
        margin-top: 75px;
    }
    /****** edited *******/

    .menu_category a {
        margin-left: 25px;
        margin-right: 25px;
        font-size: 16pt;
    }

    .douhua1 img, .douhua2 img {
        width: 45%;
        height: auto;
        min-width: 320px;
        margin: 40px;
    }

    .menu_row {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }

    .douhua2 {
        flex-direction: row-reverse;
   }

   .menu_intro {
        padding: 30px;
    }

    .menu_intro h1 {
        font-size: 18px;
        margin: initial;
        padding: 10px;
        margin: 10px;
    }

    hr {
        height: .25rem;
        width: 0%;
        margin: 0;
        background: #F9AE00;
        border: none;
        transition: .3s ease-in-out;
    }
}

@media (min-width: 1350px) {
    /*home */
    .see_more {
        font-size: 24px;
    }

    .mySlides img{
        width: 1920px; 
        position: relative; 
        left: 100%; 
        margin-left: -200%;
    }

    /* about us */
    .about_doday {
        background-color: #F9AE00;
        height: 600px;
    }
    .about_doday_img {
        display: inline-block;
        position: absolute;
        top: 250px;
        left: 60px;
        width: 580px;
        z-index: 3;
    }

    .about_intro_container {
        height: 553px;
        width: 800px;
        position: absolute;
        top: 170px;
        right: 80px;
        z-index: 2;
    }

    .about_intro {
        position: absolute;
        top: 230px;
        right: 150px;
        display: flex;
        flex-direction: column;
        width: auto;
        max-width: 550px;
        z-index: 4;
    }

    .about_doday_img_mobile, .about_doday2_img_mobile {
        display: none;
    }

    .about_intro p, .about_intro h1, .about_intro2 p, .about_intro2 h1  {
        margin: 30px;
    }

    .girl {
        display: inline-block;
        position: absolute;
        top: 630px;
        right: 150px;
        z-index: 5;
        height: 400px;
    }

    .about_intro2_container {
        height: 553px;
        width: 90%;
        position: absolute;
        top: 1000px;
        right: 0px;
        z-index: 2;
    }

    .about_intro2 {
        position: absolute;
        top: 1040px;
        left: 160px;
        display: flex;
        flex-direction: column;
        width: auto;
        max-width: 580px;
        z-index: 4;
    }

    .about_doday2_img {
        display: inline-block;
        position: absolute;
        top: 1100px;
        right: 110px;
        width: 465px;
        z-index: 3;
        box-shadow: rgba(0,0,0,0.3);
    }

    .about_doday2 {
        position: absolute;
        top: 1250px;
        height: 470px;
        width: 65%;
    }

    .about2_container {
        height: 1117px;
    }

}